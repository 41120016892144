// $fontPrimary: "Overpass", sans-serif;
$fontColor: #3495cd;

// COLORS
$color-primary: #3495cd;
$color-light: #7ed56f;
$color-dark: #28b485;

$color-grey-light-1: #f7f7f7;

$color-grey-dark: #777;
$color-white: #fff;
$color-black: #000;

// FONT
$default-font-size: 1rem;

// GRID
$grid-width: 114rem;
$gutter-vertical: 8rem;
$gutter-horizontal: 6rem;

// RESPONSIVE BREAKPOINTS
$bp-largest: 75em; // 1200px / 16
$bp-large: 62.5em; // 1000px / 16
$bp-medium: 50em; // 800px/16
$bp-small: 37.5em; // 600px / 16
