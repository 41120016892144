.container {
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	//	background-image: linear-gradient(
	//		to bottom,
	//		rgba(#ffff, 0.8),
	//		rgba(#2f2f2f, 0.8)
	//	);

	//img {
	//  width: 250px;
	//  margin-top: -10rem;
	//  margin-bottom: 15rem;
	//}
	//p {
	//font-size: 3rem;
	//  text-align: center;
	//}
	//height: 100vh;
	//padding: 4rem;
	//color: $fontColor;
	//font-weight: 400;
	//display: flex;
	//flex-direction: column;
	//flex-wrap: nowrap;
	//justify-content: center;
	/*
  .header {
    display: flex;
    flex-direction: row;

    &__filler {
      flex-grow: 2;
    }

    &__logo {
      width: 20%;
      align-content: center;
      align-self: flex-start;
    }

    &__title {
      font-size: 5rem;
      color: #fff;

      // $bp-large: 62.5em; // 1000px / 16
      @media only screen and (max-width: $bp-large) {
        font-size: 3rem;
      }

      // $bp-medium: 50em; // 800px/16
      @media only screen and (max-width: $bp-medium) {
        font-size: 2rem;
      }
    }
    */
	// grid-template-rows: 10vh 600px 6rem 2rem;
	// grid-template-columns: 1fr 1fr;
	//@media only screen and (max-width: $bp-largest) {
	//  display: flex;
	//  flex-direction: column;
	//}
	.btn-tos {
		padding: 0;
		align-self: center;
		justify-self: center;
		margin-top: 5rem;

		@media only screen and (max-width: $bp-largest) {
		}
	}

	//	.my-slider-container {
	//		margin-top: 4rem;
	//		margin-bottom: 4rem;
	//}

	//	.tos {
	//		margin-top: 1rem;
	//		padding: 0;
	//		align-self: center;
	//		justify-self: center;
	//		font-size: 1.5rem;
	//	}
}
