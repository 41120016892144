.loggedin__container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    width: 250px;
    margin-bottom: 5rem;
  }
  p {
    text-align: center;
  }
}
