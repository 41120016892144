.btn {
	&:link,
	&:visited {
		text-transform: uppercase;
		text-decoration: none;
		//padding: 2rem 2rem;
		display: inline-block;
		// border-radius: 5rem;
		transition: all 0.2s;
		position: relative;
		//font-size: $default-font-size;
	}

	&:hover {
		transform: translateY(-0.3rem);
		box-shadow: 0 1rem 2rem rgba($color-black, 0.2);

		&::after {
			transform: scaleX(1.4) scaleY(1.6);
			opacity: 0;
		}
	}

	&:active {
		transform: translateY(-0.01rem);
		box-shadow: 0 0.5rem 1rem rgba($color-black, 0.2);
	}

	&--white {
		background-color: $color-white;
		color: $color-grey-dark;

		&::after {
			background-color: $color-white;
		}
	}

	&::after {
		content: "";
		display: inline-block;
		height: 100%;
		width: 100%;
		//border-radius: 5rem;
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		transition: all 0.4s;
	}

	&--animated {
		animation: moveInBottom 0.5s ease-out 0.75s;
		animation-fill-mode: backwards;
	}
}

.btn-text {
	&:link,
	&:visited {
		//@extend: $default-font-size;
		color: $color-primary;
		display: inline-block;
		text-decoration: none;
		border-bottom: 1px solid $color-primary;
		padding: 2rem 2rem;
		transition: all 0.2s;
		background-color: $color-white;
	}

	&:hover {
		background-color: $color-primary;
		color: $color-white;
		box-shadow: 0 1rem 2rem rgba($color-black, 0.15);
		transform: translateX(-2px);
	}
	&:active {
		box-shadow: 0 0.5rem 1rem rgba($color-black, 0.15);
		transform: translateX(0);
	}
}
